import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import logo from '../../static/images/logo-only.svg';

function DescribeTeamTwo({ setObj, next }) {
  const [tab, setTab] = useState('all');
  return (
    <div className="signup-desc-team-two flex-grow-1 d-flex flex-column">
      <div className="col-md-7 mx-auto flex-grow-1">
        <h2 className="title">How Would You Describe Your Team?</h2>
        <label className="search-wrap">
          <input type="text" className="search my-auto" />
          <FontAwesomeIcon className="icon my-auto" icon={faSearch} />
        </label>
        <div className="tab-wrapper">
          <div className="tabs d-flex">
            <div
              onClick={() => setTab('all')}
              className={'tab-itm' + (tab === 'all' ? ' active' : '')}
            >
              All
            </div>
            <div
              onClick={() => setTab('companies')}
              className={'tab-itm' + (tab === 'companies' ? ' active' : '')}
            >
              Companies
            </div>
            <div
              onClick={() => setTab('non-profit')}
              className={'tab-itm' + (tab === 'non-profit' ? ' active' : '')}
            >
              Non-Profits
            </div>
            <div
              onClick={() => setTab('social')}
              className={'tab-itm' + (tab === 'social' ? ' active' : '')}
            >
              Social
            </div>
          </div>
          <div
            className="list-itm d-flex"
            onClick={() => {
              setObj({ name: 'Type A', logo: logo });
              next();
            }}
          >
            <h6>Type A</h6> <img src={logo} alt="" />
          </div>
          <div
            className="list-itm d-flex"
            onClick={() => {
              setObj({ name: 'Type B', logo: logo });
              next();
            }}
          >
            <h6>Type B</h6> <img src={logo} alt="" />
          </div>
          <div
            className="list-itm d-flex"
            onClick={() => {
              setObj({ name: 'Type C', logo: logo });
              next();
            }}
          >
            <h6>Type C</h6> <img src={logo} alt="" />
          </div>
          <div
            className="list-itm d-flex"
            onClick={() => {
              setObj({ name: 'Type D', logo: logo });
              next();
            }}
          >
            <h6>Type D</h6> <img src={logo} alt="" />
          </div>
          <div
            className="list-itm d-flex"
            onClick={() => {
              setObj({ name: 'Type E', logo: logo });
              next();
            }}
          >
            <h6>Type E</h6> <img src={logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DescribeTeamTwo;
