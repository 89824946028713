import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../static/images/logo-only.svg';
import cs from '../static/images/customer-support.svg';
import DescribeTeam from '../components/SignupPage/DescribeTeam';
import DescribeTeamTwo from '../components/SignupPage/DescribeTeamTwo';

function SignUpPage() {
  const [step, setStep] = useState(0);
  const next = () => {
    setStep((step) => step + 1);
  };
  const [obj, setObj] = useState('');
  function getStep() {
    switch (step) {
      case 0:
        return <DescribeTeam next={next} />;
      case 1:
        return <DescribeTeamTwo setObj={setObj} next={next} />;
      case 2:
        return <DescribeTeam obj={obj} />;
      default:
        break;
    }
  }
  return (
    <div className="signup-page h-100 d-flex flex-column">
      <div className="d-flex flex-grow-1">
        <div className="col-md-6 digi-hr d-flex flex-column">
          <div className="img-corner">
            <img src={logo} alt="" />
          </div>
          <div className="detail my-auto">
            <h2>
              Digitalizing Human
              <br />
              Resources
            </h2>
            <p className="mb-auto">
              Create, automate, and scale your team with the help
              <br />
              of TeamForce™.
            </p>
          </div>
          <div className="customer-care d-flex">
            <img src={cs} alt="" />
            <h6 className="ml-3">Speak To A Representative</h6>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column second-side">
          {getStep()}
          <div className="already-account d-flex mt-auto">
            <h6 className="ml-3">
              Already have an account ?<Link to="login"> Click Here</Link>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
