import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import HomePage from './pages/HomePage';
import SwitcherPage from './pages/SwitcherPage';
import GetAccount from './pages/GetAccountPage';
import SelectTeamPage from './pages/SelectTeamPage';
import TeamAppsPage from './pages/TeamAppsPage';
import TeamLogin from './pages/TeamLogin';
import TeamSignup from './pages/TeamSignup';
import GetStartedPage from './pages/GetStartedPage';

function Routes() {
  return (
    <Switch>
      <Route exact path="/getAccount" component={GetAccount} />
      <Route exact path="/selectTeamApp" component={TeamAppsPage} />
      <Route exact path="/register" component={TeamAppsPage} />
      <Route exact path="/selectTeam" component={SelectTeamPage} />
      <Route exact path="/getstarted" component={SignUpPage} />
      <Route exact path="/:type/login" component={TeamLogin} />
      <Route exact path="/:type/signup" component={TeamSignup} />
      <Route exact path="/:type/signupFresh" component={GetStartedPage} />
      <Route exact path="/:type/:category/login" component={TeamLogin} />
      <Route exact path="/login" component={LandingPage} />
      <Route exact path="/log-in" component={LoginPage} />
      <Route exact path="/" component={HomePage} />
      <Route exact path="*" component={SwitcherPage} />
    </Switch>
  );
}

export default Routes;
