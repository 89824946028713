import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import '@teamforce/broker-admin-dashboards/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './static/scss/master.scss';
import Routes from './Routes';
import TeamForceContextProvider from './context/Context';
import { ParallaxProvider } from 'react-scroll-parallax';
import TabsContextProvider from './context/TabsContext';
import GamesContextProvider from './context/GameContext';

const App = () => (
  <ParallaxProvider>
    <TeamForceContextProvider>
      <BrowserRouter>
        <GamesContextProvider>
          <TabsContextProvider>
            <Routes />
          </TabsContextProvider>
        </GamesContextProvider>
      </BrowserRouter>
    </TeamForceContextProvider>
  </ParallaxProvider>
);

export default App;
