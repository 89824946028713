import React from 'react';

function GetStartedSetPassword({
  setStepName,
  logo,
  setPassword,
  password,
  validateCircle,
  isValid,
  setPasswordReqModal,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Great Job. Now Assign A Permenant Password</div>
      <div className="group">
        <input
          type="password"
          className="input password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setStepName('confirmPassword');
            }
          }}
        />
        {validateCircle(isValid)}
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setPasswordReqModal(true)}>
          Password Requirements
        </div>
        <div className="btnNext" onClick={() => setStepName('confirmPassword')}>
          Next
        </div>
      </div>
    </div>
  );
}

export default GetStartedSetPassword;
