import React from 'react';

function SignupSetPassword({
  logo,
  password,
  setPassword,
  setSignupStep,
  isValid,
  createInbox,
  setPasswordReqModal,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Create Your Password</div>
      <div className={`group ${isValid.password}`}>
        <input
          type="password"
          className="input password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSignupStep(3);
              try {
                createInbox();
              } catch (error) {}
            }
          }}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setPasswordReqModal(true)}>
          Password Requirements
        </div>
        <div
          className="btnNext"
          onClick={() => {
            setSignupStep(3);
            try {
              createInbox();
            } catch (error) {}
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupSetPassword;
