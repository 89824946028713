import React, { useContext, useState } from 'react';
// import { Navbar, ViewSwitcher } from '@teamforce/broker-admin-dashboards';
import { Link, Redirect } from 'react-router-dom';

import { TeamForceContext } from '../context/Context';
import Layout from '../Layouts/Layout';
import { TabsContext } from '../context/TabsContext';
import FABHeart from '../components/FABHeart/FABHeart';
import { GamesContext } from '../context/GameContext';

const tabs = ['Gametime', 'Plays', 'Sub-Teams', 'Coaches', 'Team'];
function DashboardPage({ hide }) {
  const { email } = useContext(TeamForceContext);
  const { navTabs } = useContext(TabsContext);
  const [isList, setIsList] = useState(false);
  const [tab, setTab] = useState('Gametime');
  const { selectedTeam } = useContext(GamesContext);
  return (
    <>
      {email === '' ? <Redirect to="/login" /> : ''}
      {!selectedTeam ? <Redirect to="/selectTeam" /> : ''}
      <Layout
        hide={hide}
        active="Arena"
        // navbar={
        //   <Navbar
        //     navTabs={navTabs}
        //     tabs={tabs}
        //     tabSelected={tab}
        //     setTabSelected={(tab) => setTab(tab)}
        //     search
        //     setSearch={() => {}}
        //     viewSwitcher={
        //       <ViewSwitcher isList={isList} setIsList={setIsList} />
        //     }
        //     tabClick={() => {}}
        //     searchPlaceHolder=""
        //     button={
        //       <Link to="/selectTeam" className="button">
        //         <img
        //           src={
        //             selectedTeam?.team?.teamDetails?.logo ||
        //             selectedTeam?.team?.teamDetails?.icon
        //           }
        //           alt=""
        //         />
        //         <span>{selectedTeam?.team?.teamName}</span>
        //       </Link>
        //     }
        //   />
        // }
      >
        <FABHeart />
      </Layout>
    </>
  );
}

export default DashboardPage;
