import React from 'react';
import { useHistory } from 'react-router-dom';

function GetStartedSelectProcess({ setStepName, logo, setSignUpType }) {
  const history = useHistory();
  return (
    <div className="stepWrapper selectProcess">
      <img
        src={logo}
        alt="assetLogo"
        className="assetLogo"
        onClick={() => history.push('/')}
      />
      <div className="desc">
        If This Is Your First Time Here. You Can Register For Free.
        <br /> Select Which Process Applies To You
      </div>
      <div className="actions">
        <div
          className="btnAction"
          onClick={() => {
            setStepName('preReg');
            setSignUpType('preReg');
          }}
        >
          I Was Pre-Registered
        </div>
        <div
          className="btnAction inv"
          onClick={() => {
            setStepName('bySelf');
            setSignUpType('bySelf');
          }}
        >
          I Got Here By Myself
        </div>
        <div
          className="btnAction"
          onClick={() => {
            setStepName('byBroker');
            setSignUpType('byBroker');
          }}
        >
          I Was Referred By A Broker
        </div>
      </div>
    </div>
  );
}

export default GetStartedSelectProcess;
