import React from 'react';

function GetStartedSetConfirmPassword({
  setStepName,
  logo,
  confirmPassword,
  setConfirmPassword,
  onSubmit,
  validateCircle,
  isValid,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Great Job. Now Confirm Your Password</div>
      <div className="group">
        <input
          type="password"
          className="input password"
          placeholder="Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setStepName('success');
            }
          }}
        />
        {validateCircle(isValid)}
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setStepName('setPassword')}>
          Change Password
        </div>
        <div
          className="btnNext"
          onClick={() => {
            setStepName('success');
            try {
              onSubmit();
            } catch (error) {}
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default GetStartedSetConfirmPassword;
