import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import signUpBg from '../static/images/appListIcons/signUpBg.jpg';
import tfLogo from '../static/images/logos/teamforceFullLength.svg';
import gxIcn from '../static/images/signupIcons/gx.svg';
import notaIcn from '../static/images/signupIcons/nota.svg';
import snappyIcn from '../static/images/sidebarColorIcons/snappy.svg';

function TeamSignup() {
  const history = useHistory();
  const { type } = useParams();
  return (
    <div className="teamSignup">
      <img src={signUpBg} alt="" className="signupBg" />
      <div className="signupArea">
        <div className="selectType">
          <img src={tfLogo} alt="" className="tfLogo" />
          <div className="desc">
            Which Of The Following Statements Describe You
          </div>
          <div className="listItem">
            <img src={snappyIcn} alt="" className="icon" />
            <span>Im A Snapay User</span>
          </div>
          <div className="listItem">
            <img src={gxIcn} alt="" className="icon" />
            <span>I Am A User Of A GX Application</span>
          </div>
          <div
            className="listItem"
            onClick={() => history.push(`/${type}/signupFresh`)}
          >
            <img src={notaIcn} alt="" className="icon" />
            <span>I Am None Of The Above</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamSignup;
