import React, { Fragment, useContext } from 'react';
import { TabsContext } from '../context/TabsContext';
import DashboardPage from './DashboardPage';

function SwitcherPage() {
  const { tabIndex, tabs } = useContext(TabsContext);
  function getNavHead(tab, i) {
    switch (true) {
      case '/arena' === tab.location:
        return (
          <Fragment key={tab.key}>
            <DashboardPage hide={i !== tabIndex} />
          </Fragment>
        );

      default:
        break;
    }
  }
  return <>{tabs.map((tab, i) => getNavHead(tab, i))}</>;
}

export default SwitcherPage;
