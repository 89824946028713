import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../static/images/logo.svg';

function LandingPage() {
  return (
    <div className="landing-page h-100 d-flex flex-column">
      <div className="container d-flex m-auto flex-grow-1">
        <div className="col-md-6 m-auto">
          <img className="logo" src={logo} alt="" />
          <Link to="log-in">
            <button className="bt-outline" type="button">
              Login
            </button>
          </Link>
          <hr />
          <Link to="getstarted">
            <button className="bt-solid" type="button">
              Signup
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
