import Axios from 'axios';
import React, { useContext, useState } from 'react';

import { TeamForceContext } from '../../context/Context';
import { GamesContext } from '../../context/GameContext';

import logo from '../../static/images/gameLogos/gameTime.svg';
import LoadingAnim from '../LoadingAnim/LoadingAnim';

function ModalLogout({ onClose, gameId }) {
  const { email, idToken, tostShowOn } = useContext(TeamForceContext);
  const { getGames } = useContext(GamesContext);
  const [loading, setLoading] = useState(false);
  const [resp, setResp] = useState('');
  function logout() {
    setLoading(true);
    Axios.post(
      `https://teamforce.apimachine.com/game/exitGame/logout/${gameId}`,
      {},
      {
        headers: {
          email,
          token: idToken,
        },
      }
    )
      .then(({ data }) => {
        setResp(data);
        getGames();
      })
      .catch((e) => {
        tostShowOn(e.response.data.message);
      })
      .finally(() => setLoading(false));
  }
  return (
    <div className="modalLogout">
      <div
        className="overlayClose"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalContent">
        <div className="modalHead">
          <img src={logo} alt="" />
        </div>
        {loading ? (
          <div className="modalContents">
            <LoadingAnim />
          </div>
        ) : resp ? (
          <div className="modalContents">
            <div className="text">The Game Is Now Over</div>
            <div className="buttons">
              <div
                className="btn-confirm"
                onClick={() => {
                  try {
                    onClose();
                  } catch (error) {}
                }}
              >
                Take Me Home
              </div>
            </div>
          </div>
        ) : (
          <div className="modalContents">
            <div className="text">
              Are You Sure You Want To Clock Out And End This Game?
            </div>
            <div className="buttons">
              <div className="btn-confirm">No</div>
              <div className="btn-cancel" onClick={() => logout()}>
                I’m Sure
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalLogout;
