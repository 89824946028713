/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import logo from '../static/images/logo.svg';
import { TeamForceContext } from '../context/Context';

function LoginPage() {
  const history = useHistory();
  const [emailId, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, tostShowOn } = useContext(TeamForceContext);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [googlePin, setGooglePin] = useState('');
  const userCheck = (email, token) => {
    Axios.get('https://teamforce.apimachine.com/users', {
      headers: { email, token },
    })
      .then(({ data }) => {
        if (history) {
          history.push('/selectTeam');
        }
      })
      .catch((e) => {
        if (e.response.data.statusCode === 401) {
          history.push('/getAccount');
        }
      });
  };
  const userLogin = (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) {
      setLoading(true);
      if (mfaEnabled) {
        Axios.post('https://gxauth.apimachine.com/gx/user/login', {
          email: emailId,
          password: password,
          totp_code: googlePin,
        })
          .then((response) => {
            const { data } = response;
            setLoading(false);
            if (data.status) {
              login(emailId, data.accessToken, data.idToken);
              userCheck(emailId, data.idToken);
            } else {
              tostShowOn(data.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Axios.post('https://gxauth.apimachine.com/gx/user/login', {
          email: emailId,
          password: password,
        })
          .then((response) => {
            const { data } = response;
            setLoading(false);
            if (data.status) {
              login(emailId, data.accessToken, data.idToken);
              userCheck(emailId, data.idToken);
            } else if (data.mfa) {
              setMfaEnabled(true);
            } else {
              tostShowOn(data.message);
            }
          })
          .catch((error) => {
            tostShowOn('Some Thing Went Wrong!');
            setLoading(false);
          });
      }
    } else {
      tostShowOn('Enter Valid Email');
    }
  };
  return (
    <>
      <div className="login-page h-100 d-flex flex-column">
        <div className="container d-flex m-auto">
          <form className="login-form col-md-6 m-auto" onSubmit={userLogin}>
            <Zoom>
              <img className="logo" src={logo} alt="" />
            </Zoom>
            {mfaEnabled ? (
              <Fade bottom>
                <div className="group">
                  <input
                    type="password"
                    value={googlePin}
                    onChange={(e) => setGooglePin(e.target.value)}
                    name="twofa"
                    required="required"
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <label>Enter Google Authenticator Code</label>
                </div>
              </Fade>
            ) : (
              <>
                <Fade bottom>
                  <div className="group">
                    <input
                      type="text"
                      name="email"
                      value={emailId}
                      onChange={(e) => setEmail(e.target.value)}
                      required="required"
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label>Email</label>
                  </div>
                </Fade>
                <Fade bottom>
                  <div className="group">
                    <input
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required="required"
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor="password">Password</label>
                  </div>
                </Fade>
              </>
            )}
            <Fade bottom>
              <button type="submit" disabled={loading} className="btn bt-solid">
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'LOGIN'}
              </button>
            </Fade>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
