import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { TeamForceContext } from '../context/Context';

import logo from '../static/images/logo.svg';
import bg1 from '../static/images/appListIcons/loginBg1.jpg';
import bg2 from '../static/images/appListIcons/loginBg2.jpg';
import bg3 from '../static/images/appListIcons/loginBg3.jpg';
import bg4 from '../static/images/appListIcons/loginBg4.jpg';

function TeamLogin() {
  const history = useHistory();
  const { type, category } = useParams();
  const [emailId, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, tostShowOn } = useContext(TeamForceContext);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [googlePin, setGooglePin] = useState('');
  const userCheck = (email, token) => {
    Axios.get('https://teamforce.apimachine.com/users', {
      headers: { email, token },
    })
      .then(({ data }) => {
        if (history) {
          history.push('/selectTeam');
        }
      })
      .catch((e) => {
        if (e.response.data.statusCode === 401) {
          history.push('/getAccount');
        }
      });
  };
  const userLogin = (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) {
      setLoading(true);
      if (mfaEnabled) {
        Axios.post('https://gxauth.apimachine.com/gx/user/login', {
          email: emailId,
          password: password,
          totp_code: googlePin,
        })
          .then((response) => {
            const { data } = response;
            setLoading(false);
            if (data.status) {
              login(emailId, data.accessToken, data.idToken);
              userCheck(emailId, data.idToken);
            } else {
              tostShowOn(data.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Axios.post('https://gxauth.apimachine.com/gx/user/login', {
          email: emailId,
          password: password,
        })
          .then((response) => {
            const { data } = response;
            setLoading(false);
            if (data.status) {
              login(emailId, data.accessToken, data.idToken);
              userCheck(emailId, data.idToken);
            } else if (data.mfa) {
              setMfaEnabled(true);
            } else {
              tostShowOn(data.message);
            }
          })
          .catch((error) => {
            tostShowOn('Some Thing Went Wrong!');
            setLoading(false);
          });
      }
    } else {
      tostShowOn('Enter Valid Email');
    }
  };
  const [actveBg, setActveBg] = useState('');
  useEffect(() => {
    switch (true) {
      case type === 'players':
        setActveBg(bg1);
        break;
      case type === 'teams' && !category:
        setActveBg(bg2);
        break;
      case type === 'teams' && category === 'freelancers':
        setActveBg(bg3);
        break;
      case type === 'teams' && category === 'hrfirms':
        setActveBg(bg4);
        break;
      default:
        break;
    }
  }, [type, category]);
  return (
    <div className="teamLogin">
      <div className="loginArea">
        <form className="login-form m-auto" onSubmit={userLogin}>
          <Zoom>
            <img className="logo" src={logo} alt="" />
          </Zoom>
          {mfaEnabled ? (
            <Fade bottom>
              <div className="group">
                <input
                  type="password"
                  value={googlePin}
                  onChange={(e) => setGooglePin(e.target.value)}
                  name="twofa"
                  required="required"
                />
                <span className="highlight" />
                <span className="bar" />
                <label>Enter Google Authenticator Code</label>
              </div>
            </Fade>
          ) : (
            <>
              <Fade bottom>
                <div className="group">
                  <input
                    type="text"
                    name="email"
                    value={emailId}
                    onChange={(e) => setEmail(e.target.value)}
                    required="required"
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <label>Email</label>
                </div>
              </Fade>
              <Fade bottom>
                <div className="group">
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required="required"
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <label htmlFor="password">Password</label>
                </div>
              </Fade>
            </>
          )}
          <Fade bottom>
            <button type="submit" disabled={loading} className="bt-solid">
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'LOGIN'}
            </button>
          </Fade>
          <div className="signUpBt">
            New To Teamforce?
            <span
              onClick={() => {
                history.push(`/${type}/signup`);
              }}
            >
              {' '}
              Click Here
            </span>
          </div>
        </form>
      </div>
      <div className="loginBg">
        <img src={actveBg} alt="" className="loginImg" />
      </div>
    </div>
  );
}

export default TeamLogin;
