import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { TeamForceContext } from '../context/Context';
import { GamesContext } from '../context/GameContext';
import tfLogo from '../static/images/logos/teamforceFullLength.svg';

function SelectTeamPage() {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const { userTeams, setSelectedTeam } = useContext(GamesContext);
  const { email } = useContext(TeamForceContext);
  useEffect(() => {
    if (!email) {
      history.push('/login');
    }
  }, [email]);
  return (
    <div className="selectTeamPage">
      <img src={tfLogo} alt="" className="tfLogo" />
      <label className="boxSearch">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Whose Facilities Do You Want To Visit Today? "
        />
        <FontAwesomeIcon icon={faSearch} className="icon" />
      </label>
      <Scrollbars className="teamListGrid">
        {userTeams
          .filter((team) => {
            return team?.team?.teamName
              ?.toLowerCase()
              .includes(search?.toLowerCase());
          })
          .map((team) => (
            <div
              className="gridBox"
              key={team._id}
              onClick={() => {
                setSelectedTeam(team);
                history.push('/arena');
              }}
            >
              <img
                src={
                  team?.team?.teamDetails?.logo || team?.team?.teamDetails?.icon
                }
                alt={team?.team?.teamName}
              />
              <div className="appName">{team?.team?.teamName}</div>
            </div>
          ))}
        <div className="gridBox">
          <div className="plus" />
          <span>Add New Team</span>
        </div>
      </Scrollbars>
    </div>
  );
}

export default SelectTeamPage;
