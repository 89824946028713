import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Axios from 'axios';
import { TeamForceContext } from './Context';

export const GamesContext = createContext();

function GamesContextProvider({ children }) {
  const { email, idToken, tostShowOn } = useContext(TeamForceContext);
  const [selectedTeam, setSelectedTeam] = useState('');
  // const [state, setstate] = useState(initialState);
  const [userTeams, setUserTeams] = useState([]);
  const getUserTeams = useCallback(() => {
    if (email && idToken)
      Axios.get('https://teamforce.apimachine.com/team/userTeams', {
        headers: {
          email,
          token: idToken,
        },
      }).then(({ data }) => setUserTeams(data));
  }, [email, idToken]);

  const getGames = useCallback(() => {
    if (email && idToken)
      Axios.get('https://teamforce.apimachine.com/game/all', {
        headers: {
          email,
          token: idToken,
        },
      })
        .then(({ data }) => setAllGames(data))
        .catch((e) => {
          console.log(e.response.data.message);
        });
  }, [email, idToken]);

  const startGame = useCallback(
    (team) => {
      team &&
        Axios.post(
          `https://teamforce.apimachine.com/game/recordLogin/${team?.teamId}`,
          {},
          {
            headers: {
              email,
              token: idToken,
            },
          }
        )
          .then(({ data }) => {
            tostShowOn('Game Started');
            getGames();
          })
          .catch((e) => {
            tostShowOn(e.response.data.message);
          });
    },
    [email, idToken]
  );
  const [allGames, setAllGames] = useState([]);

  useEffect(() => {
    getUserTeams();
    getGames();
  }, [email, idToken]);

  return (
    <GamesContext.Provider
      value={{
        userTeams,
        getUserTeams,
        setSelectedTeam,
        selectedTeam,
        startGame,
        getGames,
        allGames,
      }}
    >
      {children}
    </GamesContext.Provider>
  );
}

export default GamesContextProvider;
