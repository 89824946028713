import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import tfFull from '../static/images/logos/teamforceFullLength.svg';
import tfFullApp from '../static/images/logos/teamforceFull.svg';

function LandingNavbar({ menuShow, active }) {
  const history = useHistory();
  const [navOpen, setNavOpen] = useState(false);
  return (
    <nav className={`landing-nav ${!menuShow}`}>
      <div
        onClick={() => setNavOpen(!navOpen)}
        className={
          'd-flex hamburger hamburger--squeeze' + (navOpen ? ' is-active' : '')
        }
      >
        <span className="hamburger-box m-auto">
          <span className="hamburger-inner"></span>
        </span>
      </div>
      {menuShow && (
        <div className="nav-menu">
          <a href="https://teamforce.chat" target="_blank" className="bt-login">
            <div className="insider" />
            Chat
          </a>
        </div>
      )}
      <img
        src={tfFull}
        onClick={() => history.push('/')}
        alt=""
        className="market-logo"
      />
      {menuShow && (
        <div className="nav-menu">
          <Link to="/selectTeamApp" className="bt-login">
            <div className="insider" />
            Apps
          </Link>
        </div>
      )}
      {navOpen && (
        <div className="navDrawer">
          <img src={tfFullApp} alt="" className="mobileNavLogo" />
          <Link to="/" className={`navItem ${active === 'home'}`}>
            Home
          </Link>
          <Link to="/selectTeamApp" className={`navItem ${active === 'apps'}`}>
            Apps
          </Link>
          <Link
            to="/partnerSelect"
            className={`navItem ${active === 'partners'}`}
          >
            Leagues
          </Link>
        </div>
      )}
    </nav>
  );
}

export default LandingNavbar;
