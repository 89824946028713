import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import fabHeart from '../../static/images/fabIcon.svg';
import { ReactComponent as FabClose } from '../../static/images/fabClose.svg';
import fabClose from '../../static/images/fabClose.svg';
import fabHide from '../../static/images/fabHide.svg';
import arrows from '../../static/images/arrows.svg';
import arrowsDown from '../../static/images/arrowsDown.svg';
import agentAi from '../../static/images/fabMenu/agentAi.svg';
import coachAi from '../../static/images/fabMenu/coachAi.svg';
import search from '../../static/images/fabMenu/search.svg';
import watchAi from '../../static/images/fabMenu/watchAi.svg';
import paySlip from '../../static/images/fabMenu/paySlip.svg';
import checkOut from '../../static/images/fabMenu/checkOut.svg';
import gamePreviewLogo from '../../static/images/gameLogos/gamePreview.svg';
import gameTime from '../../static/images/gameLogos/gameTime.svg';
import hands from '../../static/images/gameLogos/hands.svg';
import endGame from '../../static/images/gameLogos/endGame.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Scrollbars from 'react-custom-scrollbars';
import { GamesContext } from '../../context/GameContext';
import { TeamForceContext } from '../../context/Context';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import ModalConfirm from '../ModalLogout/ModalLogout';

function FABHeart() {
  const history = useHistory();
  const [gameTimeOpen, setGameTimeOpen] = useState(false);
  const [fabOpen, setFabOpen] = useState(false);
  const {
    setSelectedTeam,
    selectedTeam,
    userTeams,
    startGame,
    allGames,
  } = useContext(GamesContext);
  const [timeNow, setTimeNow] = useState(new Date());
  useEffect(() => {
    let inter = setInterval(() => {
      setTimeNow(new Date());
    }, 1000);
    return () => {
      clearInterval(inter);
    };
  }, []);
  const { profilePic, profileName } = useContext(TeamForceContext);
  const [gamePreview, setGamePreview] = useState(false);
  const [logout, setLogout] = useState('');
  const [step, setStep] = useState(0);
  return (
    <>
      {gameTimeOpen && (
        <>
          <div className={`overlayFab ${gamePreview}`}>
            <div className="head">
              <img
                src={gameTime}
                alt=""
                className="logo"
                onClick={() => history.push('/')}
              />
              {step === 0 && (
                <>
                  <div className="text">
                    Engage With Your Team By Clicking Here
                  </div>
                  <div className="arrows">
                    <img src={arrows} alt="" className="" />
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <div className="text">
                    Which Of Your Teams Are You Starting The Game With?
                  </div>
                  <div className="arrows down">
                    <img src={arrowsDown} alt="" className="" />
                  </div>
                </>
              )}
              <div className="newSession" onClick={() => setStep(1)}>
                <FontAwesomeIcon icon={faPlus} className="icon" />
                New Game
              </div>
              <FabClose
                className="closeOverlay"
                onClick={() => {
                  setFabOpen(false);
                  setGameTimeOpen(false);
                }}
              />
            </div>
            {step === 1 && (
              <Scrollbars className="teamListScroll">
                {userTeams.map((team) => (
                  <div
                    className={`teamItem ${selectedTeam === team}`}
                    onClick={() => {
                      setSelectedTeam(team);
                      setTimeout(() => {
                        setGamePreview(team);
                        setFabOpen(false);
                      }, 100);
                    }}
                  >
                    <img
                      src={
                        team?.team?.teamDetails?.logo ||
                        team?.team?.teamDetails?.icon
                      }
                      alt={team?.team?.teamName}
                      className="logo"
                    />
                    <span>{team?.team?.teamName}</span>
                  </div>
                ))}
              </Scrollbars>
            )}
            <Scrollbars className="content">
              {allGames &&
                allGames.map((game) => (
                  <div className="currentSession">
                    <div className="sessionHead">
                      <span>{game.endDate ? '' : 'Current '}Session</span>
                      <span>
                        {FormatNumber(
                          ((game.endDate || moment().valueOf()) -
                            game.startDate) /
                            3600000,
                          2
                        )}{' '}
                        Hrs
                      </span>
                    </div>
                    <div className="contentSession">
                      <div className="headText">Minutes</div>
                      <div className="txtItm">
                        <span>Checked In </span>
                        <span>{moment(game.startDate).format('hh:mm A')}</span>
                      </div>
                      <div className="txtItm">
                        <span>Checked Out</span>
                        {game.endDate ? (
                          <span>{moment(game.endDate).format('hh:mm A')}</span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="contentSession">
                      <div className="headText">Earnings</div>
                      <div className="txtItm">
                        <span>Gross</span>
                        <span className="plus">
                          {FormatCurrency(
                            game?.compensation?.amount,
                            game?.compensation?.currency
                          )}{' '}
                          {game?.compensation?.currency}
                        </span>
                      </div>
                      <div className="txtItm">
                        <span>Bonuses</span>
                        <span className="plus">$0 USD</span>
                      </div>
                      <div className="txtItm">
                        <span>Deductions</span>
                        <span className="minus">$0 USD</span>
                      </div>
                    </div>
                    <div className="btns">
                      <div className="btnBig">
                        <img src={paySlip} alt="" />
                        <span>PaySlip</span>
                      </div>
                      <div className="btnBig">
                        <img src={checkOut} alt="" />
                        <span>Check Out</span>
                      </div>
                      <div
                        className="btnBig"
                        onClick={() => setLogout(game._id)}
                      >
                        <img src={endGame} alt="" />
                        <span>End Game</span>
                      </div>
                    </div>
                  </div>
                ))}
            </Scrollbars>
            {logout && (
              <ModalConfirm onClose={() => setLogout('')} gameId={logout} />
            )}
            {gamePreview && (
              <div className={`gamePreviewWrapper`}>
                <div className="gamePreview">
                  <div className="headLogo">
                    <img src={gamePreviewLogo} alt="" className="logo" />
                    <div
                      className="btClose"
                      onClick={() => setGamePreview(false)}
                    >
                      <img src={fabClose} alt="" className="" />
                    </div>
                  </div>
                  <div className="versus">
                    <div className="user">
                      <img src={profilePic} alt="" />
                      <span className="name">{profileName}</span>
                    </div>
                    <img src={hands} alt="" className="hands" />
                    <div className="user">
                      <img src={selectedTeam?.team?.teamDetails?.logo} alt="" />
                      <span className="name">
                        {selectedTeam?.team?.teamName}
                      </span>
                    </div>
                  </div>
                  <div className="time">
                    <div className="tz">
                      {moment.tz(moment.tz.guess()).zoneAbbr()}
                    </div>
                    <div className="apm">{moment(timeNow).format('A')}</div>
                    <div className="times">
                      {moment(timeNow).format('hh:mm:ss')}
                    </div>
                    <div className="date">
                      {moment(timeNow).format('dddd MMMM Do YYYY')}
                    </div>
                  </div>
                  <div
                    className="gameTime"
                    onClick={() => startGame(gamePreview)}
                  >
                    <img src={gameTime} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
          {fabOpen && (
            <div className="fabMenu">
              <div className="menuItm">
                <img src={search} alt="" className="icon" />
                <span>Search</span>
              </div>
              <div className="menuItm">
                <img src={watchAi} alt="" className="icon" />
                <span>Watch</span>
              </div>
              <div className="menuItm">
                <img src={coachAi} alt="" className="icon" />
                <span>Coach.ai</span>
              </div>
              <div className="menuItm">
                <img src={agentAi} alt="" className="icon" />
                <span>Agent.ai</span>
              </div>
            </div>
          )}
        </>
      )}

      <div
        className="fabHeart"
        onClick={() => {
          setGameTimeOpen(true);
          setFabOpen(!fabOpen);
        }}
      >
        <img src={fabOpen ? fabHide : fabHeart} alt="" />
      </div>
    </>
  );
}

export default FABHeart;
