import React from 'react';
import guest from '../../../static/images/guest.jpg';

function SignupSetDp({ uploadImage, thumbnailLink, setSignupStep, logo }) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Set Profile Pic</div>
      <label className="dp">
        <img src={thumbnailLink || guest} alt="" />
        <input type="file" accept="image/*" onChange={uploadImage} />
      </label>
      <div className="btns">
        <div className="btnBig" onClick={() => setSignupStep()}>
          Previos
        </div>
        <div className="btnNext" onClick={() => setSignupStep(1)}>
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupSetDp;
