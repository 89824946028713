import React, { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import { NavbarTab } from '@teamforce/broker-admin-dashboards';
import { v4 as uuid } from 'uuid/dist';

import arenaIcon from '../static/images/sidebarColorIcons/arena.svg';

export const TabsContext = createContext();

function TabsContextProvider({ children }) {
  const history = useHistory();
  const [tabs, setTabs] = useState([{ location: '/', key: '0' }]);
  const [tabIndex, setTabIndex] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    let tempArr = tabs.slice();
    tempArr[tabIndex] = { ...tempArr[tabIndex], location: pathname };
    setTabs(tempArr);
    // eslint-disable-next-line
  }, [pathname, setTabs]);

  function newTab(to = '/arena') {
    setTabIndex(tabs.length);
    setTabs((tabs) => [...tabs, { location: to, key: uuid() }]);
    history.push(to);
  }
  function removeTab(i) {
    setTabs((tabs) => tabs.filter((v, index) => index !== i));
    let indexTemp = tabIndex - 1 || 0;
    if (indexTemp < 1) indexTemp = 0;
    history.push(tabs[indexTemp]);
    setTabIndex(indexTemp);
  }
  // function getNavHead(tab, i, onClose) {
  //   switch (true) {
  //     case '/arena' === tab.location:
  //       return (
  //         <NavbarTab
  //           key={tab.key}
  //           logo={arenaIcon}
  //           text="Arena"
  //           onClick={() => {
  //             setTabIndex(i);
  //             history.push(tab.location);
  //           }}
  //           active={i === tabIndex}
  //           onClose={tabs && tabs.length && tabs.length > 1 && onClose}
  //         />
  //       );
  //     default:
  //       break;
  //   }
  // }

  const navTabs = (
    <div className="navList">
      {/* {tabs.map((tab, i) =>
        getNavHead(tab, i, () => {
          setTimeout(() => {
            removeTab(i);
          }, 200);
        })
      )} */}
      <div className="addNav" onClick={() => newTab()} />
    </div>
  );

  return (
    <TabsContext.Provider
      value={{
        tabs,
        setTabs,
        tabIndex,
        setTabIndex,
        navTabs,
        newTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}

export default TabsContextProvider;
