import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import JsonWebToken from 'jsonwebtoken';
import { MailSlurp } from 'mailslurp-client';
import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';

import GetStartedGetTempCred from '../components/GetStartedComponents/GetStartedGetTempCred';
import GetStartedSelectProcess from '../components/GetStartedComponents/GetStartedSelectProcess';
import GetStartedSetConfirmPassword from '../components/GetStartedComponents/GetStartedSetConfirmPassword';
import GetStartedSetPassword from '../components/GetStartedComponents/GetStartedSetPassword';
import GetStartedSuccess from '../components/GetStartedComponents/GetStartedSuccess';
import GetStartedFindBroker from '../components/GetStartedComponents/GetStartedFindBroker';
import GetStartedBrokerDetail from '../components/GetStartedComponents/GetStartedBrokerDetail';
import GetStartedBrokerDetailInvalid from '../components/GetStartedComponents/GetStartedBrokerDetailInvalid';
import GetStartedSignupForm from '../components/GetStartedComponents/GetStartedSignupForm';
import SignupSidebar from '../components/GetStartedComponents/SignupSidebar';
import PasswordRequirementModal from '../components/GetStartedComponents/PasswordRequirementModal';
import NotSeeingMailModal from '../components/GetStartedComponents/NotSeeingMailModal';
import SignupPreregSidebar from '../components/GetStartedComponents/SignupPreregSidebar';
import GetStartedSetConfirmOtp from '../components/GetStartedComponents/GetStartedSetConfirmOtp';
import SignupResetSidebar from '../components/GetStartedComponents/SignupResetSidebar';

import logo from '../static/images/logos/teamforceFullLength.svg';
import loginBg from '../static/images/appListIcons/signUpBg.jpg';
import { TeamForceContext } from '../context/Context';
import LoadingAnim from '../components/LoadingAnim/LoadingAnim';

const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
const capRegex = new RegExp(/^.*[A-Z].*/);
const numRegex = new RegExp(/^.*[0-9].*/);
const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);
// const otpRegex = new RegExp(/^\d*$/);

const mailslurp = new MailSlurp({
  apiKey: '86cee2f39d56b3b5a6b2e4c827cc1382d1be6bad16a9d35cd0e659ef9272d02c',
});

const secret = 'uyrw7826^&(896GYUFWE&*#GBjkbuaf'; // secret not to be disclosed anywhere.
const emailDev = 'rahulrajsb@outlook.com'; // email of the developer.

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

function GetStartedPage() {
  const { tostShowOn, tempMail, tempPass } = useContext(TeamForceContext);
  const location = useLocation();
  const [signUpType, setSignUpType] = useState('');
  const [stepName, setStepName] = useState('');
  const [customEmail, setCustomEmail] = useState(false);
  const [notSeeing, setNotSeeing] = useState(false);

  const [success, setSuccess] = useState(false);
  const validateCircle = (isValid) => (
    <>
      {isValid ? (
        <svg
          className={`circle ${isValid}`}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 0C4.93436 0 0 4.93436 0 11C0 17.0656 4.93436 22 11 22C17.0656 22 22 17.0656 22 11C22 4.93436 17.0656 0 11 0Z"
            fill="#464B4E"
          />
          <path
            d="M16.5753 8.66925L10.6169 14.6275C10.4381 14.8062 10.2035 14.8962 9.96881 14.8962C9.73416 14.8962 9.49951 14.8062 9.32076 14.6275L6.34164 11.6484C5.98312 11.29 5.98312 10.7106 6.34164 10.3522C6.7 9.99373 7.27924 9.99373 7.63776 10.3522L9.96881 12.6833L15.2791 7.37314C15.6375 7.01462 16.2167 7.01462 16.5753 7.37314C16.9336 7.73149 16.9336 8.31073 16.5753 8.66925Z"
            fill="#FAFAFA"
          />
        </svg>
      ) : (
        <svg
          className={`circle ${isValid}`}
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7" cy="7" r="7" fill={isValid ? ' #464B4E' : '#BE241A'} />
        </svg>
      )}
    </>
  );

  const [mailNUnames, setMailNUnames] = useState({
    emails: [],
    usernames: [],
  });
  useEffect(() => {
    Axios.get('https://comms.globalxchange.com/listUsernames').then((res) => {
      const { data } = res;
      if (data.status) {
        let bytes = CryptoJS.Rabbit.decrypt(data.payload, 'gmBuuQ6Er8XqYBd');
        let jsonString = bytes.toString(CryptoJS.enc.Utf8);
        let result_obj = JSON.parse(jsonString);
        setMailNUnames(result_obj);
      }
    });
  }, []);

  const [brokerDetails, setBrokerDetails] = useState({});
  const [signupStep, setSignupStep] = useState();
  const [passwordReqModal, setPasswordReqModal] = useState(false);
  //Form Values
  const [isValid, setIsValid] = useState({});
  const [mailId, setMailId] = useState('');
  const [userName, setUserName] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pin, setPin] = useState('');
  const [isBrokerEmail, setIsBrokerEmail] = useState(false);
  const [brokerEmailOrId, setBrokerEmailOrId] = useState('');
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [now, setNow] = useState();
  const [thumbLoading, setThumbLoading] = useState(false);
  const [thumbnailLink, setThumbnailLink] = useState('');

  const uploadImage = async (e) => {
    setThumbLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf('.')
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append('files', file);
    const path_inside_brain = 'root/';
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: 'HS512',
        expiresIn: 240,
        issuer: 'gxjwtenchs512',
      }
    );
    console.log('file,fileName', file, fileName);
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    setThumbnailLink(data.payload.url);
    setThumbLoading(false);
  };

  useEffect(() => {
    setIsValid({
      uname: !mailNUnames.usernames.includes(userName) && userName.length > 3,
      email: emailRegex.test(mailId) && !mailNUnames.emails.includes(mailId),
      password:
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password) &&
        password.length >= 8,
      confirmPassword: confirmPassword === password,
      pin: String(pin).length === 6,
    });
  }, [userName, mailId, password, confirmPassword, pin, mailNUnames]);

  function updatePassword() {
    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: mailId,
      password: tempPassword,
      newPassword: password,
    }).then(({ data }) => {
      if (data.status) {
        setStepName('success');
        setSuccess(true);
      }
    });
  }

  function onConfirmPassword() {
    if (signUpType === 'preReg' || signUpType === 'reset') {
      updatePassword();
    }
  }
  const [brokerDetailLoading, setBrokerDetailLoading] = useState(false);
  function getBrokerDetail(isBrokerEmail, email, step) {
    setBrokerDetailLoading(true);
    Axios.get(
      `https://comms.globalxchange.com/user/details/get?${
        (isBrokerEmail ? 'email=' : 'username=') + email
      }`
    )
      .then(({ data }) => {
        if (data.status) {
          setBrokerDetails(data.user);
          !step && setStepName('brokerDetailValid');
        } else {
          !step && setStepName('brokerDetailInvalid');
        }
      })
      .finally(() => setBrokerDetailLoading(false));
  }

  const [inbox, setInbox] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendMail, setResendMail] = useState(false);

  const createInbox = async () => {
    const inboxController = mailslurp.inboxController;
    const inbox = await inboxController.createInbox(
      undefined,
      `${userName}@blockcheck.io`,
      undefined,
      undefined,
      undefined,
      `${fName} ${lName}`,
      ['Teamforce']
    );
    setInbox(inbox);
  };

  const verifyEmail = () => {
    Axios.post('https://gxauth.apimachine.com/gx/user/confirm', {
      email: mailId,
      code: pin,
    })
      .then((res) => {
        const { data } = res;
        if (!data.status) {
          tostShowOn(data.message);
        } else {
          if (location.pathname !== '/reset') {
            setStepName('success');
            setSuccess(true);
            Axios.post(
              `https://testchatsioapi.globalxchange.io/register_user`,
              {
                email: mailId,
                app_id: 'f9697fae-5877-4d96-9970-7d0936eee6d0',
              }
            );
            Axios.post(
              `https://testchatsioapi.globalxchange.io/register_with_chatsio`,
              {
                first_name: fName, // required
                last_name: lName,
                username: userName, // required
                bio: '',
                email: mailId, // required
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                avatar: thumbnailLink,
              }
            );
          } else {
            setStepName('setPassword');
          }
        }
      })
      .catch((err) => {
        tostShowOn(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function getAppDetail() {
    Axios.get(
      'https://comms.globalxchange.com/gxb/apps/get?app_code=teamforce'
    ).then(({ data }) => {
      if (data.status)
        data?.apps &&
          data?.apps[0]?.created_by &&
          getBrokerDetail(true, data.apps[0].created_by, true);
    });
  }

  function resendMailNow() {
    Axios.post('https://gxauth.apimachine.com/gx/user/confirm/resend', {
      email: inbox?.emailAddress,
    }).then(() => waitEmail());
  }

  useEffect(() => {
    if (location.pathname === '/reset') {
      setSignUpType('reset');
      setStepName('confirmOtp');
      setMailId(tempMail);
      setTempPassword(tempPass);
    }
  }, [location, tempMail, tempPass]);

  function getStep() {
    switch (stepName) {
      case 'preReg':
        return (
          <GetStartedGetTempCred
            setStepName={setStepName}
            logo={logo}
            setMailId={setMailId}
            mailId={mailId}
            setTempPassword={setTempPassword}
            tempPassword={tempPassword}
            setLoading={setLoading}
          />
        );
      case 'setPassword':
        return (
          <GetStartedSetPassword
            setStepName={setStepName}
            logo={logo}
            password={password}
            setPassword={setPassword}
            validateCircle={validateCircle}
            isValid={isValid?.password}
            setPasswordReqModal={setPasswordReqModal}
          />
        );
      case 'confirmPassword':
        return (
          <GetStartedSetConfirmPassword
            setStepName={setStepName}
            logo={logo}
            password={password}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            onSubmit={() => {
              onConfirmPassword();
            }}
            validateCircle={validateCircle}
            isValid={isValid?.confirmPassword}
          />
        );
      case 'confirmOtp':
        return (
          <GetStartedSetConfirmOtp
            logo={logo}
            mailId={mailId}
            pin={pin}
            setPin={setPin}
            resendMailNow={resendMailNow}
            verifyEmail={verifyEmail}
          />
        );
      case 'byBroker':
        return (
          <GetStartedFindBroker
            logo={logo}
            setStepName={setStepName}
            isBrokerEmail={isBrokerEmail}
            brokerEmailOrId={brokerEmailOrId}
            setBrokerEmailOrId={setBrokerEmailOrId}
            setIsBrokerEmail={setIsBrokerEmail}
            onSubmit={() => {
              getBrokerDetail(isBrokerEmail, brokerEmailOrId);
            }}
          />
        );
      case 'brokerDetailValid':
        return (
          <GetStartedBrokerDetail
            logo={logo}
            setStepName={setStepName}
            brokerDetails={brokerDetails}
            setBrokerEmailOrId={setBrokerEmailOrId}
          />
        );
      case 'brokerDetailInvalid':
        return (
          <GetStartedBrokerDetailInvalid
            logo={logo}
            setStepName={setStepName}
            setBrokerEmailOrId={setBrokerEmailOrId}
          />
        );
      case 'bySelf':
        getAppDetail();
        setStepName('signUpForm');
        return '';
      case 'signUpForm':
        return (
          <GetStartedSignupForm
            setStepName={setStepName}
            logo={logo}
            isValid={isValid}
            fName={fName}
            lName={lName}
            userName={userName}
            setFName={setFName}
            setLName={setLName}
            mailId={mailId}
            setUserName={setUserName}
            signupStep={signupStep}
            setSignupStep={setSignupStep}
            setMailId={setMailId}
            customEmail={customEmail}
            setCustomEmail={setCustomEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            pin={pin}
            setPin={setPin}
            createInbox={createInbox}
            resendMail={resendMail}
            setResendMail={setResendMail}
            verifyEmail={verifyEmail}
            setPasswordReqModal={setPasswordReqModal}
            now={now}
            setNotSeeing={setNotSeeing}
            resendMailNow={resendMailNow}
            uploadImage={uploadImage}
            thumbnailLink={thumbnailLink}
          />
        );

      case 'success':
        return (
          <GetStartedSuccess
            logo={logo}
            mailId={mailId}
            password={password}
            loading={loading}
            setLoading={setLoading}
            username={userName}
            name={`${fName} ${lName}`}
          />
        );

      default:
        return (
          <GetStartedSelectProcess
            setStepName={setStepName}
            logo={logo}
            setSignUpType={setSignUpType}
          />
        );
    }
  }

  const waitEmail = async () => {
    setNow(Date.now() + 30000);
    const email = await mailslurp.waitController.waitForLatestEmail(
      inbox.id,
      30000,
      true
    );
    // const pattern = 'use this code <strong>([0-9]{6})&nbsp;</strong>';
    const pattern = '([0-9]{6})';
    try {
      const result = await mailslurp.emailController.getEmailContentMatch(
        { pattern },
        email.id
      );
      setPin(result?.matches[1]);
    } catch (e) {
      setResendMail(true);
    }
  };

  const signUp = () => {
    Axios.post('https://gxauth.apimachine.com/gx/user/signup', {
      username: userName,
      email: mailId,
      password: password,
      ref_affiliate: brokerDetails.affiliate_id || '1',
      account_type: 'Personal',
      signedup_app: 'teamforce',
    })
      .then((res) => {
        if (!res.data.status) {
          tostShowOn(res.data.message);
        } else {
          waitEmail();
        }
      })
      .catch((err) => {
        tostShowOn(err.message);
      });
  };

  useEffect(() => {
    if (signupStep === 4) {
      if (customEmail) signUp();
      else if (inbox.id) signUp();
    }
  }, [signupStep, customEmail, inbox]);

  function getSideContent() {
    switch (true) {
      case stepName === 'signUpForm':
        return (
          <SignupSidebar signupStep={signupStep} customEmail={customEmail} />
        );
      case signUpType === 'preReg':
        return <SignupPreregSidebar stepName={stepName} />;
      case signUpType === 'reset':
        return <SignupResetSidebar stepName={stepName} />;

      default:
        return <img className="bgImage" src={loginBg} alt="" />;
    }
  }

  return (
    <div className="getStartedPage">
      {!success && getSideContent()}
      {getStep()}
      {(brokerDetailLoading || loading) && (
        <div
          className="loadingAnim"
          style={stepName === 'success' ? { backgroundColor: 'white' } : {}}
        >
          <LoadingAnim />
        </div>
      )}
      {passwordReqModal && (
        <PasswordRequirementModal
          password={password}
          validateCircle={validateCircle}
          onClose={() => setPasswordReqModal(false)}
        />
      )}
      {notSeeing && <NotSeeingMailModal onClose={() => setNotSeeing(false)} />}
    </div>
  );
}

export default GetStartedPage;
