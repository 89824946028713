import React from 'react';
import LandingNavbar from '../Layouts/LandingNavbar';
import useWindowDimensions from '../utils/WindowSize';
import teamApps from '../static/images/gameApps.svg';
import Scrollbars from 'react-custom-scrollbars';
import empIcon from '../static/images/appListIcons/teamIcon1.jpg';
import teamIcon from '../static/images/appListIcons/teamIcon2.jpg';
import freelanceIcon from '../static/images/appListIcons/teamIcon3.jpg';
import hrIcon from '../static/images/appListIcons/teamIcon4.jpg';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

function TeamAppsPage() {
  const { height } = useWindowDimensions();
  const { path } = useRouteMatch();
  const loginSignup = path === '/register' ? 'signup' : 'login';
  return (
    <div className="teamAppPage" style={{ height }}>
      <section className="homePage">
        <LandingNavbar active="home" menuShow={false} />
        <div className="pageContent">
          <div className="mainContent">
            <div className="createTeam">
              <span>Create A Team, </span>
              <span>Join The Revolution</span>
            </div>
            <div className="desc">
              The Workplace Has Changed Forever. In The Post 2020 World, The
              Concept Of Collaboartion Goes Far Beyond The Walls Of A Building
              Of Even The Confinds Of A Company. The New Workplace Is One Of
              Teams.{' '}
            </div>
            <div className="btnCreate">Create A Team</div>
          </div>
        </div>
        <div className="footerMain">Already Managing A Team? Click Here</div>
      </section>
      <div className="sidebar">
        <div className="topLogoWrap">
          <img src={teamApps} alt="" className="imgLogo" />
        </div>
        <Scrollbars className="scrollList">
          <Link to={`/players/${loginSignup}`} className="listItm">
            <img src={empIcon} alt="" />
            <span>For Employees</span>
          </Link>
          <Link to={`/teams/${loginSignup}`} className="listItm">
            <img src={teamIcon} alt="" />
            <span>For Teams</span>
          </Link>
          <Link to={`/teams/freelancers/${loginSignup}`} className="listItm">
            <img src={freelanceIcon} alt="" />
            <span>For Free Lancers</span>
          </Link>
          <Link to={`/teams/hrfirms/${loginSignup}`} className="listItm">
            <img src={hrIcon} alt="" />
            <span>For HR Firms</span>
          </Link>
        </Scrollbars>
      </div>
    </div>
  );
}

export default TeamAppsPage;
