import React from 'react';

function SignupConfirmPassword({
  logo,
  confirmPassword,
  setConfirmPassword,
  setSignupStep,
  isValid,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Confirm Your Password</div>
      <div className={`group ${isValid.confirmPassword}`}>
        <input
          type="password"
          className="input password"
          placeholder="Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSignupStep(4);
            }
          }}
        />
      </div>
      <div className="btns">
        <div className="btnBig" onClick={() => setSignupStep(2)}>
          Change Password
        </div>
        <div className="btnNext" onClick={() => setSignupStep(4)}>
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupConfirmPassword;
