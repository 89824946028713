import React from 'react';

function SignupSetUsernameEmail({
  logo,
  isValid,
  userName,
  mailId,
  setUserName,
  setMailId,
  setSignupStep,
  customEmail,
  setCustomEmail,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      {customEmail ? (
        <>
          <div className="stepDesc">Create Username And Enter Your Email</div>
          <div className={`group ${isValid.uname}`}>
            <input
              type="text"
              className="input email"
              placeholder="Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className={`group ${isValid.email}`}>
            <input
              type="text"
              className="input email"
              placeholder="Email"
              value={mailId}
              onChange={(e) => setMailId(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  isValid.uname && isValid.email && setSignupStep(2);
                }
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="stepDesc">Choose A BlockCheck ID</div>
          <div className={`group ${isValid.uname}`}>
            <input
              type="text"
              className="input email"
              placeholder="Ex. shorupan"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
                setMailId(`${e.target.value}@blockcheck.io`);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  isValid.uname && isValid.email && setSignupStep(2);
                }
              }}
            />
            <div className="domain">@blockcheck.io</div>
          </div>
        </>
      )}
      <div className="btns">
        <div className="btnBig" onClick={() => setCustomEmail(!customEmail)}>
          {customEmail ? 'Use BlockCheck ID' : 'Use Custom Email'}
        </div>
        <div
          className={`btnNext ${isValid.uname && isValid.email}`}
          onClick={() => isValid.uname && isValid.email && setSignupStep(2)}
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default SignupSetUsernameEmail;
