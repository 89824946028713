import React, { createContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Toast from '../components/Toast/Toast';

export const TeamForceContext = createContext();

function TeamForceContextProvider({ children }) {
  const [email, setEmail] = useState(
    localStorage.getItem('teamForceLoginAccount') || ''
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('teamForceAccessToken') || ''
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem('teamForceIdToken') || ''
  );

  useEffect(() => {
    localStorage.setItem('teamForceLoginAccount', email);
  }, [email]);
  useEffect(() => {
    localStorage.setItem('teamForceAccessToken', accessToken);
  }, [accessToken]);
  useEffect(() => {
    localStorage.setItem('teamForceIdToken', idToken);
  }, [idToken]);

  const login = (paramEmail, paramAccessToken, paramIdToken) => {
    setEmail(paramEmail);
    setAccessToken(paramAccessToken);
    setIdToken(paramIdToken);
  };

  const [profilePic, setProfilePic] = useState('');
  const [profileName, setProfileName] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (email && idToken) {
      Axios.post('https://comms.globalxchange.com/coin/verifyToken', {
        email,
        token: idToken,
      }).then((res) => (res.data.status ? '' : login('', '', '')));

      Axios.get(
        `https://comms.globalxchange.com/user/details/get?email=${email}`
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          setProfileName(data.user.name);
          setProfilePic(data.user.profile_img);
          setUsername(data.user.username);
        }
      });
    }
  }, [email, idToken]);

  useEffect(() => {
    if (email && idToken) {
      Axios.post('https://comms.globalxchange.com/coin/verifyToken', {
        email,
        token: idToken,
      }).then((res) => (res.data.status ? '' : login('', '', '')));
    }
  }, [email, idToken]);

  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const tostShowOn = (message) => {
    setToastShow(true);
    setToastMessage(message);
    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  return (
    <TeamForceContext.Provider
      value={{
        tostShowOn,
        login,
        email,
        idToken,
        profileName,
        profilePic,
        username,
      }}
    >
      {children}
      <Toast show={toastShow} message={toastMessage} />
    </TeamForceContext.Provider>
  );
}

export default TeamForceContextProvider;
