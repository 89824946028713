// import { MainLayout } from '@teamforce/broker-admin-dashboards';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { TeamForceContext } from '../context/Context';
// import MySidebar from './Sidebar';

function Layout({ navbar, active, hide, children }) {
  const { email } = useContext(TeamForceContext);
  return (
    <>
      {!email && <Redirect to="/login" />}
      {/* <MainLayout
        className=""
        hide={hide}
        sidebar={<MySidebar active={active} />}
        primaryColor="#f45d48"
        navbar={navbar}
      > */}
        {children}
      {/* </MainLayout> */}
    </>
  );
}

export default Layout;
