import React, { useState } from 'react';

function DescribeTeam({ next, obj }) {
  const [tab, setTab] = useState('all');
  const [acceptTermsCheck, setacceptTermsCheck] = useState(false);
  return (
    <div className="signup-desc-team flex-grow-1 d-flex flex-column">
      <div className="col-md-7 mx-auto flex-grow-1">
        <h2 className="title">How Would You Describe Your Team?</h2>
        <div className="tabs d-flex">
          <div
            onClick={() => setTab('all')}
            className={'tab-itm' + (tab === 'all' ? ' active' : '')}
          >
            All
          </div>
          <div
            onClick={() => setTab('companies')}
            className={'tab-itm' + (tab === 'companies' ? ' active' : '')}
          >
            Companies
          </div>
          <div
            onClick={() => setTab('non-profit')}
            className={'tab-itm' + (tab === 'non-profit' ? ' active' : '')}
          >
            Non-Profits
          </div>
          <div
            onClick={() => setTab('social')}
            className={'tab-itm' + (tab === 'social' ? ' active' : '')}
          >
            Social
          </div>
        </div>
        <div className="group">
          <h6>Team Type</h6>
          {obj ? (
            <div className="team">
              {obj.name} <img src={obj.logo} alt="" />
            </div>
          ) : (
            <input type="text" name="team" placeholder="Ex. Church Choir...." />
          )}
          <div>
            <input
              type="checkbox"
              id="termsncditions"
              name="termsncditions"
              checked={acceptTermsCheck}
              onChange={(e) => setacceptTermsCheck(e.target.checked)}
            />
            <label htmlFor="termsncditions">
              I Agree To All The Terms & Conditions
            </label>
          </div>
          <button
            className="bt-solid"
            onClick={() => {
              try {
                next();
              } catch (error) {}
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default DescribeTeam;
