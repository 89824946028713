import Axios from 'axios';
import React from 'react';

function GetStartedGetTempCred({
  setStepName,
  logo,
  setMailId,
  mailId,
  setTempPassword,
  tempPassword,
  setLoading,
}) {
  function tempLogin() {
    setLoading(true);
    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: mailId,
      password: tempPassword,
    })
      .then(({ data }) => {
        if (data.resetPassword) {
          setStepName('setPassword');
        }
      })
      .finally(() => setLoading(false));
  }
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">
        Enter The Temporaray Credentials You Got In Your Email
      </div>
      <div className="group">
        <input
          type="text"
          className="input email"
          placeholder="Email Id"
          value={mailId}
          onChange={(e) => setMailId(e.target.value)}
        />
      </div>
      <div className="group">
        <input
          type="password"
          className="input password"
          placeholder="Password"
          value={tempPassword}
          onChange={(e) => setTempPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              tempLogin();
            }
          }}
        />
      </div>
      <div className="group btnSingle" onClick={() => tempLogin()}>
        NEXT STEP
      </div>
    </div>
  );
}

export default GetStartedGetTempCred;
