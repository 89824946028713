import React from 'react';
import userIcon from '../../static/images/avatar.svg';

function GetStartedBrokerDetailInvalid({
  setStepName,
  logo,
  setBrokerEmailOrId,
}) {
  return (
    <div className="stepWrapper stepForm">
      <img src={logo} alt="assetLogo" className="assetLogo" />
      <div className="stepDesc">Is This Your Broker?</div>
      <div className="userDetail">
        <div className="invalid">
          <img src={userIcon} alt="" className="profileImg" />
          <div className="mainText">
            The Information You Entered Doesn’t Match Any Of Our Brokers
          </div>
          <div className="tryAgain">Please Try Again</div>
        </div>
      </div>
      <div className="btns">
        <div
          className="btnBig"
          onClick={() => {
            setStepName('');
            setBrokerEmailOrId('');
          }}
        >
          Back To Login
        </div>
        <div
          className="btnNext w50"
          onClick={() => {
            setStepName('byBroker');
            setBrokerEmailOrId('');
          }}
        >
          Try Again
        </div>
      </div>
    </div>
  );
}

export default GetStartedBrokerDetailInvalid;
