import React, { useContext, useEffect, useState } from 'react';
import JsonWebToken from 'jsonwebtoken';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

import { TeamForceContext } from '../context/Context';

import guest from '../static/images/guest.jpg';

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = 'uyrw7826^&(896GYUFWE&*#GBjkbuaf'; // secret not to be disclosed anywhere.
const emailDev = 'rahulrajsb@outlook.com'; // email of the developer.

function GetAccount() {
  const history = useHistory();
  const {
    tostShowOn,
    email,
    idToken,
    profileName,
    profilePic,
    username,
  } = useContext(TeamForceContext);
  const [linkedUrl, setLinkedUrl] = useState('');
  const [thumbLoading, setThumbLoading] = useState(false);
  const [thumbnailLink, setThumbnailLink] = useState('');
  const uploadImage = async (e) => {
    setThumbLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf('.')
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append('files', file);
    const path_inside_brain = 'root/';
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: 'HS512',
        expiresIn: 240,
        issuer: 'gxjwtenchs512',
      }
    );
    console.log('file,fileName', file, fileName);
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    setThumbnailLink(data.payload.url);
    setThumbLoading(false);
  };
  const [step, setStep] = useState(0);
  const [tag, setTag] = useState('');

  function registerUser() {
    Axios.post(
      'https://teamforce.apimachine.com/users',
      {
        name: profileName || username,
        tag: tag,
        userDetials: {
          profilepicture: thumbnailLink || profilePic,
          LinkedIn: linkedUrl,
        },
      },
      {
        headers: {
          email,
          token: idToken,
        },
      }
    ).then(({ data }) => {
      if (data.email) {
        tostShowOn('User Created');
        history.push('/selectTeam');
      }
    });
  }

  useEffect(() => {
    if (!email) history.push('/login');
  }, [email]);

  function getStep() {
    switch (step) {
      case 0:
        return (
          <div className="formContent" key="0">
            <div className="title">Complete</div>
            <div className="subTitle">Your Registration In A Flash</div>
            <div className="group">
              <input
                type="text"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                name="twofa"
                required="required"
              />
              <span className="highlight" />
              <span className="hint">What Is A Jersey Number? </span>
              <label>Whats Your Jersey Number?</label>
            </div>
            <div
              className="btContinue"
              onClick={() => {
                if (tag) setStep(1);
                else tostShowOn('Enter A Value');
              }}
            >
              Continue
            </div>
          </div>
        );
      case 1:
        return (
          <div className="formContent" key="1">
            <label className="dp">
              <img src={thumbnailLink || profilePic || guest} alt="" />
              <input type="file" accept="image/*" onChange={uploadImage} />
            </label>
            <div className="uname">{profileName || username}</div>
            <div className="group">
              <input
                type="text"
                value={linkedUrl}
                onChange={(e) => setLinkedUrl(e.target.value)}
                name="twofa"
                required="required"
              />
              <span className="highlight" />
              <label>Enter Your LinkedIn Url</label>
            </div>
            <div
              className="btContinue"
              onClick={() => {
                if (thumbLoading) tostShowOn('Please Wait Image Is Uploading!');
                else registerUser();
              }}
            >
              Continue
            </div>
          </div>
        );

      default:
        break;
    }
  }
  return (
    <div className="getAccountPage">
      <div className="content">{getStep()}</div>
      <div className="bg"></div>
    </div>
  );
}

export default GetAccount;
