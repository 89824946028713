import React, { useEffect, useState } from 'react';

import LandingNavbar from '../Layouts/LandingNavbar';
import useWindowDimensions from '../utils/WindowSize';
import bg1 from '../static/images/homeCarousel/bg1.jpg';
import bg2 from '../static/images/homeCarousel/bg2.jpg';
import bg3 from '../static/images/homeCarousel/bg3.svg';
import { Link } from 'react-router-dom';

let inter;
const bgs = [bg1, bg2, bg3];
function HomePage() {
  const { height } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    try {
      let val = 0;
      inter = setInterval(() => {
        setIndex((val + 1) % 3);
        val++;
      }, 2000);
    } catch (error) {}
    return () => {
      try {
        clearInterval(inter);
      } catch (error) {}
    };
  }, []);
  return (
    <div style={{ height }}>
      <section className="homePage">
        <LandingNavbar menuShow active="home" />
        <div className="pageContent">
          <div className="mainContent">
            <div className="createTeam">
              <span>Create A Team, </span>
              <span>Join The Revolution</span>
            </div>
            <div className="desc">
              The Workplace Has Changed Forever. In The Post 2020 World, The
              Concept Of Collaboartion Goes Far Beyond The Walls Of A Building
              Of Even The Confinds Of A Company. The New Workplace Is One Of
              Teams.{' '}
            </div>
            <Link to="/register" className="btnCreate">
              Join Now
            </Link>
          </div>
          <div className="carouselArea">
            <div
              className="carouselItem"
              style={{ backgroundImage: `url(${bgs[index]})` }}
            />
            <div
              className="carouselItem"
              style={{
                backgroundImage: `url(${bgs[index === 2 ? 0 : index + 1]})`,
              }}
            />
            <div
              className="carouselItem"
              style={{
                backgroundImage: `url(${
                  bgs[index === 0 ? index + 2 : index - 1]
                })`,
              }}
            />
          </div>
        </div>
        <div className="footerMain">Already Managing A Team? Click Here</div>
      </section>
    </div>
  );
}

export default HomePage;
